import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Experience fine dining and multiple award-winning cuisines, Bombay
        Nights Restaurants is London's renowned Indian restaurant since 1993.
      </Typography>

      <Typography variant="body1" paragraph>
        {/* cspell: disable-next-line */}
        The Executive chef Minu Pinto and his team have brought their vision to
        life to provide authentic and real Indian food, in an elegant, warm and
        friendly environment.
      </Typography>

      <Typography variant="body1" paragraph>
        Our wines are carefully chosen by accredited experts, ensuring every
        option complements your dinner choice.
      </Typography>

      <Typography variant="body1" paragraph>
        If you suffer from allergies, please enquire when ordering. Our dishes
        may contain: Gluten, Nuts, Milk, Peanuts, Soya, Mustard, Sesame Seeds,
        Lupin, Egg, Crustaceans, Celery, Fish/Fish Bone, Molluscs, Sulphur
        Dioxide. All our dishes are prepared using vegetable oil which contains
        soya. Customers with any allergies, eat at their own risk. Please call
        us before ordering if you have any questions.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
